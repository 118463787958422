.tabbed-content {
    --nav-padding: 5px;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        margin-bottom: calc(var(--paragraph-margin) - var(--nav-padding));
    }

    &__nav-wrapper {
        width: 100%;
        z-index: 999;
        position: sticky;
        top: 0;
        // background: var(--color-white);
        margin-bottom: calc(20px - var(--nav-padding));
        padding: var(--nav-padding);

        @include desktop-up {
            margin-bottom: calc(65px - var(--nav-padding));
        }
    }

    &__nav {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        align-items: center;
        justify-content: center;
        max-width: 440px;
        width: 100%;
        margin: 0 auto;

        li {
            list-style: none;
            margin: 0;
            padding: 0;
            flex: 1 1 50%;
            text-align: center;

            &:not(:first-child) {
                .tabbed-content__nav__link {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            &:not(:last-child) {
                .tabbed-content__nav__link {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        &__link {
            display: block;

            &.active {
                --button-bg: var(--button-bg-hover);
                --button-color: var(--button-color-hover);
            }
        }
    }

    &__content {
    }

    &__tab {
        display: grid;
        grid-template-areas:
            "image"
            "content";
        gap: 22px;
        align-items: center;

        @include desktop-up {
            gap: 44px;
            grid-template-columns: repeat(2, 1fr);

            &--text-image {
                grid-template-areas: "content image";

                &.preserve-image {
                    grid-template-columns: 1fr auto;
                }
            }

            &--image-text {
                grid-template-areas: "image content";

                &.preserve-image {
                    grid-template-columns: auto 1fr;
                }
            }
        }

        &__image {
            grid-area: image;
            position: relative;
            text-align: center;

            @include desktop-up {
                height: 440px;
            }

            &__img {
                width: 400px;
                max-width: 100%;

                @include desktop-up {
                    width: auto;
                    height: 100%;
                }
            }

            &__background {
                background: center / cover no-repeat;

                @include desktop-down {
                    aspect-ratio: 576 / 435;
                }

                @include desktop-up {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                }
            }
        }

        &__content {
            grid-area: content;
            @include remove-end-margin();

            .button {
                margin-top: calc(var(--step-spacing) - var(--paragraph-margin));
            }
        }
    }

    .tab--in-real-life {
    }
}
