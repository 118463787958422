@mixin desktop-up() {
    @media screen and (min-width: #{$desktop-breakpoint}) {
        @content;
    }
}

@mixin desktop-down() {
    @media screen and (max-width: #{$desktop-breakpoint - 1}) {
        @content;
    }
}

@mixin two-k-up() {
    @media screen and (min-width: #{$two-k-breakpoint}) {
        @content;
    }
}

@mixin two-k-down() {
    @media screen and (max-width: #{$two-k-breakpoint - 1}) {
        @content;
    }
}

@mixin remove-end-margin {
    > *:last-child {
        margin-bottom: 0;
    }
}
