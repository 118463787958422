.form-upload {
    aspect-ratio: var(--aspect-ratio);
    position: relative;
    border: 3px dashed var(--color-white);

    &:hover,
    &:focus,
    &:focus-within {
        border-color: var(--color-teal);

        @include desktop-down {
            border-color: var(--color-white);
        }

        .button {
            background-color: var(--color-black);
            color: var(--color-white);
        }
    }

    &--has-image {
        // border: none;
        opacity: 0;
    }

    .file-upload {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        padding: 0;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:focus-within {
            border: none;
            outline: none;
        }

        &__icon,
        &__title,
        &__button {
            pointer-events: none;
        }

        &__icon {
            width: 25%;
        }

        &__title {
            margin: 20px 0 29px;
            font-size: 20px;
        }

        &__button {
            width: 218px;
            box-sizing: border-box;
        }
    }
}
