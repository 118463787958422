nav {
    padding: 13px 0;

    @include desktop-up {
        padding: 16px 0;
    }

    .container {
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--grid-gutter);

        @include desktop-down {
            justify-content: center;
        }

        .nav__logo {
            padding-top: 6px;

            @include desktop-up {
                padding-top: 12px;
            }
        }

        .nav__button {
            .button {
                @include desktop-down {
                    padding: 11px 15px;
                    font-size: 12px;
                    line-height: 1;
                }
            }
        }
    }

    .site-logo {
        img {
            max-width: 111px;

            @include desktop-up {
                max-width: 250px;
            }
        }
    }
}
