body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: var(--text-color);
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-weight: var(--font-weight-normal);
}

#root {
}

.App {
    width: 100%;

    .form-wrapper {
        flex: 1 0 auto;
        width: 100%;
    }

    footer {
        width: 100%;
    }
}

.container {
    width: 100%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: var(--section-padding-y) var(--grid-gutter);
    box-sizing: border-box;

    // @include desktop-up {
    //     padding: 0 var(--grid-gutter);
    // }
}

ul,
ol {
    &.no-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: var(--heading-font-family);
    font-weight: var(--font-weight-bold);
    margin-top: 0;
    margin-bottom: var(--heading-margin);
    color: var(--color-darkGrey);
}
h1,
.h1 {
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);

    @include desktop-up {
        margin-bottom: 40px;
    }
}
h2,
.h2 {
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
}
h3,
.h3 {
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);
}
h4,
.h4 {
    font-size: var(--h4-font-size);
    line-height: var(--h4-line-height);
}
h5,
.h5 {
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
}
h6,
.h6 {
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
}

p {
    margin-top: 0;
    margin-bottom: var(--paragraph-margin);
}

b,
strong {
    font-weight: var(--font-weight-semibold);
}

a {
    color: inherit;
    text-decoration: none;
    text-decoration: underline;
    font-weight: var(--font-weight-semibold);

    &:hover,
    &:focus {
        color: var(--color-coral);
    }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    clip: rect(0, 0, 0, 0) !important;
    border: 0 !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important;
}
