.form-heading {
    text-align: center;
}

.form {
    display: grid;
    grid-template-areas:
        "image"
        "editor"
        "actions";
    gap: var(--grid-gutter);
    color: var(--color-white);

    &--mobile {
        max-width: 500px;
        margin: 0 auto;

        .form-message__arrows {
            justify-content: flex-end;
        }
    }

    @include desktop-up {
        grid-template-areas:
            "image editor"
            "actions actions";
        grid-template-columns: repeat(2, 1fr);
    }

    &-card {
        &--image {
            grid-area: image;
        }

        &--message {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
        }

        &--actions {
            grid-area: actions;
        }

        &--editor {
            display: flex;
            flex-direction: column;
        }
    }

    &-image {
        position: relative;

        .form-canvas {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
        }
    }

    &-editor {
        grid-area: editor;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    &-message {
        flex: 1 1 auto;
    }

    &-actions {
        grid-area: actions;
    }
}
