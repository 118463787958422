.form-message {
    gap: var(--grid-gutter);
    display: flex;
    flex-direction: column;

    &--mobile {
        display: grid;
        grid-template-areas:
            "dots arrows"
            "alignment alignment";
        grid-template-columns: repeat(2, 1fr);
    }

    &--desktop {
        .form-message__image {
            --grid-gutter: 15px;

            flex: 1 1 auto;
            display: grid;
            grid-template-areas:
                "preview alignment"
                "dots alignment";
            grid-template-columns: 0.6fr 0.4fr;
            grid-template-rows: 1fr auto;
            border-radius: 8px;
            background: var(--color-white);
            padding: var(--grid-gutter);
        }
    }

    &__preview {
        grid-area: preview;
        margin-right: var(--grid-gutter);
        background: center / contain no-repeat;
    }

    &__alignment {
        grid-area: alignment;
        display: flex;
        flex-direction: column;
        color: var(--color-black);

        &--portrait {
            padding-left: var(--grid-gutter);
            border-left: 1px solid #dcdcdc;
        }

        &--landscape {
            --grid-gutter: 4px;

            .button-group {
                height: 52px;
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        .button-group {
            flex: 1 1 auto;

            &__button {
                text-transform: capitalize;
            }
        }
    }

    &__dots {
        grid-area: dots;
        display: flex;
        gap: var(--grid-gutter);
        margin: var(--grid-gutter) 0 0;
        list-style: none;
        padding: 0;
        display: flex;
        gap: 5px;
    }

    &__dot {
        margin: 0;
        list-style: none;
        padding: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #e3e3e3;
        cursor: pointer;

        &--dark {
            background-color: rgba(0, 0, 0, 0.2);
        }

        &--selected {
            background-color: var(--color-red);
        }
    }

    &__arrows {
        grid-area: arrows;
        display: flex;
        gap: 5px;
    }

    &__arrow {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: var(--color-white);
        color: var(--color-black);

        &:hover,
        &:focus {
            background: var(--color-black);
            color: var(--color-white);
        }
    }
}
