$desktop-breakpoint: 975px;
$two-k-breakpoint: 1800px;

:root {
    --color-white: #ffffff;
    --color-black: #000000;
    --color-red: #ff632d;
    --color-coral: #f7967c;
    --color-teal: #9ad6d9;
    --color-grey: #e8e8e8;
    --color-darkGrey: #282829;
    --text-color: #414141;
    --font-family: "ProximaNova", sans-serif;
    --font-weight-normal: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 800;
    --font-size: 15px;
    --line-height: 1.3;
    --heading-font-family: var(--font-family);
    --grid-gutter: 20px;
    --aspect-ratio: 3 / 4;
    --paragraph-margin: 11px;
    --section-padding-y: 31px;
    --heading-margin: 16px;
    --h1-font-size: 24px;
    --h1-line-height: 1;
    --h2-font-size: 22px;
    --h2-line-height: 1;
    --h3-font-size: 20px;
    --h3-line-height: 1;
    --h4-font-size: 16px;
    --h4-line-height: 1;
    --h5-font-size: 14px;
    --h5-line-height: 1;
    --h6-font-size: 13px;
    --h6-line-height: 1;
    --step-spacing: 35px;

    @media screen and (min-width: #{$desktop-breakpoint}) {
        --paragraph-margin: 16px;
        --section-padding-y: 73px;
        --h1-font-size: 54px;
        --h2-font-size: 34px;
        --h3-font-size: 24px;
        --step-spacing: 43px;
    }
}
