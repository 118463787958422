.form {
    .change-image {
        display: flex;
        width: 122px;
        height: 34px;
        padding: 17px 49px;
        font-size: 13px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 6px;
        background: var(--color-white);
        font-size: 13px;
        font-weight: var(--font-weight-bold);
        white-space: nowrap;

        @include desktop-up {
            font-size: 18px;
            width: 169px;
            height: 41px;
        }
    }
}
