.button {
}

button,
.button {
    --button-bg: var(--color-white);
    --button-color: var(--color-black);
    --button-bg-hover: var(--color-black);
    --button-color-hover: var(--color-white);

    border-radius: 33px;
    font-size: 20px;
    display: inline-flex;
    padding: 17px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: var(--button-bg) !important;
    color: var(--button-color) !important;
    font-weight: var(--font-weight-bold);
    border: none;
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:focus {
        &:not([disabled]) {
            --button-bg: var(--button-bg-hover);
            --button-color: var(--button-color-hover);
        }
    }

    &--red {
        --button-bg: var(--color-red);
        --button-color: var(--color-white);
        --button-bg-hover: var(--color-black);
        --button-color-hover: var(--color-white);
    }

    &--grey {
        --button-bg: var(--color-grey);
        --button-color: var(--color-black);
        --button-bg-hover: var(--color-teal);
        --button-color-hover: var(--color-black);
    }

    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
