.paw-texts {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: var(--paragraph-margin) !important;
}

.paw-text {
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 8px 14px;
    background-color: var(--color-grey);
    border-radius: 4px;
    margin-bottom: 0;

    svg {
        flex: none;
    }
}
