.step-heading {
    display: flex;
    align-items: center;
    gap: var(--grid-gutter);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--paragraph-margin);

    &:not(:first-child) {
        margin-top: var(--step-spacing);
    }

    &__number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        background-color: var(--color-red);
        font-size: 20px;
        color: var(--color-white);
    }

    &__title {
    }
}
