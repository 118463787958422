.form-card {
    padding: var(--grid-gutter);
    border-radius: 8px;

    &--teal {
        background-color: var(--color-teal);
    }

    &--coral {
        background-color: var(--color-coral);
    }

    &--disabled {
        > * {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__heading {
        display: flex;
        gap: var(--grid-gutter);
        justify-content: space-between;
        margin-bottom: var(--grid-gutter);

        .step-heading {
            margin-bottom: 0;

            &__number {
                @include desktop-down {
                    display: none;
                }
            }
        }
    }
}
