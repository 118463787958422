.form-canvas {
    aspect-ratio: var(--aspect-ratio);
    position: relative;

    &__container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    canvas {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}
