.hero {
    color: var(--color-white);
    display: flex;
    align-items: center;
    position: relative;
    font-size: 18px;
    line-height: 24px;

    @include desktop-up {
        --paragraph-margin: 40px;

        font-size: 24px;
        line-height: 30px;
        min-height: 600px;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 50%;
            z-index: 1;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.76) 30%, #000000 100%);
            opacity: 0.5;
        }
    }

    @include two-k-up() {
        aspect-ratio: 3 / 1;
    }

    .container {
        display: grid;
        grid-template-areas:
            "image"
            "content";

        @include desktop-down {
            padding: 0;
        }

        @include desktop-up {
            grid-template-areas: "content ... image";
            grid-template-columns: 5fr 1fr 6fr;
        }
    }

    &__image {
        grid-area: image;
        position: relative;

        @include desktop-down {
            aspect-ratio: 4 / 4;
        }

        @include desktop-up {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
        }

        &__background {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background: center top / cover no-repeat;
        }
    }

    &__content {
        position: relative;
        grid-area: content;
        z-index: 2;
        @include remove-end-margin();

        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
        }

        @include desktop-down {
            padding: var(--section-padding-y) var(--grid-gutter);
            background-color: var(--color-black);

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: -90px;
                right: 0;
                left: 0;
                width: 100%;
                height: 90px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 1) 100%);
            }
        }
    }

    &__title {
        margin-top: 0;
        font-size: 43px;
        line-height: 46px;
        color: var(--color-white);

        span {
            display: block;
            color: var(--color-red);
            font-size: 47px;
            line-height: 50px;

            @include desktop-down {
                padding-top: 5px;
            }
        }
    }

    &__seperator {
       margin-bottom: 10px;

       @include desktop-down {
           margin-left: calc(var(--grid-gutter) * -1);
           max-width: 320px;
       }
    }
}
