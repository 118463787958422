$fonts: (
    "ProximaNova": (
        "ProximaNova400": (
            "font-weight": 400,
        ),
        "ProximaNova600": (
            "font-weight": 600,
        ),
        "ProximaNova800": (
            "font-weight": 800,
        ),
    ),
);

@each $font-family, $font-faces in $fonts {
    @each $src, $options in $font-faces {
        @font-face {
            font-family: $font-family;
            src: url("../../public/fonts/#{$src}.woff") format("woff"),
                url("../../public/fonts/#{$src}.ttf") format("truetype");
            font-display: swap;
            @each $property, $value in $options {
                #{$property}: #{$value};
            }
        }
    }
}
