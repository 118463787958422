.button-group {
    display: flex;
    gap: var(--grid-gutter);
    border-radius: 4px;
    background: #ededed;
    padding: var(--grid-gutter);

    @include desktop-up {
        flex-direction: column;
    }

    button {
        --button-bg: transparent;
        --button-bg-hover: var(--color-white);
        --button-color-hover: var(--color-black);

        padding: 0;
        flex: 1 1 auto;
        border-radius: 4px;
        font-size: 14px;
        background: transparent;

        @include desktop-up {
            --button-bg: rgb(224, 224, 224);
        }

        &.button-group__button--selected {
            background: var(--color-white) !important;
            color: var(--color-black) !important;
        }
    }
}
