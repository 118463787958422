.form-actions {
    display: grid;
    gap: var(--grid-gutter);
    color: var(--color-black);
    align-items: flex-start;
    grid-template-areas:
        "download"
        "hashtags"
        "url"
        "caption"
        "share";

    @include desktop-up {
        grid-template-columns: 25fr 30fr 15fr 30fr;
        grid-template-areas:
            "download hashtags url caption"
            "share share share share";
    }

    button {
        width: 100%;
    }

    &__header {
        font-weight: var(--font-weight-semibold);
    }

    &__download {
        grid-area: download;
    }

    &__url {
        grid-area: url;
    }

    &__caption {
        grid-area: caption;
    }

    &__share {
        grid-area: share;
    }

    &__hashtags {
        grid-area: hashtags;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            li {
                list-style: none;
                margin: 0;
                padding: 0;
            }
        }
    }

    &__hashtag {
        display: inline-flex;
        align-items: flex-start;
        padding: 9px 16px;
        gap: 5px;
        border-radius: 30px;
        background: var(--color-white);
        text-decoration: none;
        color: inherit !important;
        position: relative;
        overflow: hidden;

        &:hover,
        &:focus {
            background: var(--color-coral);
        }

        svg {
            flex: none;
            margin-top: 0.15em;
        }

        &__copied {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            background: inherit;
        }
    }
}
