footer {
    // padding: var(--grid-gutter) 0;
    font-size: 13px;
    background-color: var(--color-grey);

    .container {
        display: grid;
        grid-template-areas:
            "title"
            "button"
            "logo"
            "text";
        align-items: center;
        padding: 30px;
        gap: var(--grid-gutter);

        @include desktop-down {
            justify-content: center;
        }

        @include desktop-up {
            padding: var(--grid-gutter);
            gap: 60px;
            grid-template-areas:
                "title button"
                "text logo";
        }
    }

    .footer__title {
        grid-area: title;
        margin-bottom: 0;

        @include desktop-down {
            text-align: center;
        }
    }

    .footer__button {
        grid-area: button;
        text-align: center;

        @include desktop-down {
            margin-bottom: calc(30px - var(--grid-gutter));
        }

        @include desktop-up {
            text-align: right;
        }
    }

    .footer__text {
        grid-area: text;
        margin-bottom: 0;
    }

    .footer__logo {
        grid-area: logo;
        text-align: center;

        @include desktop-up {
            text-align: right;
        }

        img {
            max-width: 40px;
        }
    }
}
